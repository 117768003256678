import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import clsx from "clsx";
import useFinalistsStages from "../../../components/pages/PageTypes/hooks/useFinalistsStages";

const NavItem = ({label, path, isActive}) => {
    const location = useLocation();
    return <li className={isActive ? 'active' : location.pathname === path ? 'active' : ''}>
        <NavLink
            to={{pathname: path}}
            className="itemLabel"
            activeClassName="active"
        >
					<span className="itemLabelInner">
						{label}
					</span>
        </NavLink>
    </li>
}

function Navigation() {
    const [opened, setOpened] = useState([]);
    const user = useSelector(({auth}) => auth.user);
    const location = useLocation();

    const { data: stageData, error: stageError } = useFinalistsStages();

    useEffect(() => {
        const openArray = [];
        if (location.pathname.startsWith("/admin")) {
            openArray.push("admin");
        }
        if (location.pathname.startsWith("/telstra-staff")) {
            openArray.push("telstra-staff");
        }
        if (location.pathname.startsWith("/enterprise-awards")) {
            openArray.push("enterprise-awards");
        }
        if (location.pathname.startsWith("/enterprise-awards/domain")) {
            openArray.push("domain-specialisation-awards");
        }
        if (location.pathname.startsWith("/enterprise-awards/alliance")) {
            openArray.push("alliance-awards");
        }
        if (location.pathname.startsWith("/enterprise-awards/excellence-and-values")) {
            openArray.push("excellence-and-values");
        }
        if (location.pathname.startsWith("/enterprise-awards/enterprise")) {
            openArray.push("enterprise");
        }
        if (location.pathname.startsWith("/enterprise-awards/telstra-nominated")) {
            openArray.push("telstra-nominated");
        }
        if (location.pathname.startsWith("/enterprise-awards/state-awards")) {
            openArray.push("state-awards");
        }
        if (location.pathname.startsWith("/nomination-enterprise-awards") || location.pathname.startsWith("/how-to-nominate") || location.pathname.startsWith("/nomination-invite-reviewers") || location.pathname.startsWith("/nomination") || location.pathname.startsWith("/nominate")) {
            openArray.push("nomination");
        }
        if (location.pathname.startsWith("/brand-identity")) {
            openArray.push("brand-identity");
        }
        if (location.pathname.startsWith("/brand-guidelines")) {
            openArray.push("brand-guidelines");
        }
        if (location.pathname.startsWith("/how-to-create")) {
            openArray.push("how-to-create");
        }
        if (location.pathname.startsWith("/asset-downloads")) {
            openArray.push("asset-downloads");
        }
        setOpened(openArray);
        //eslint-disable-next-line
    }, [location.pathname])

    const toggleNavItem = (e, name) => {
        e.preventDefault();
        if (isOpened(name)) {
            setOpened(opened.filter(v => v !== name));
        } else {
            setOpened([...opened, name]);
        }
    }

    const isOpened = (name) => {
        return opened.includes(name);
    }

    const resolveColor = () => {
        if (location.pathname.startsWith("/admin") || location.pathname.startsWith("/telstra-staff")) {
            return "green";
        }
        return "grey";
    }

    const hasGroup = (group) => {
        return user?.userData?.groups && user?.userData?.groups.includes(group);
    }

    return (
        <div className={`navigation ${resolveColor()}`}>
            <ul className={'topLevel'}>
                <NavItem label={"Home"} key={"nav-home"} path={"/"}/>
                {/*<li>*/}
                {/*    <a href={"#"} className="itemLabel"*/}
                {/*       onClick={(e) => toggleNavItem(e, 'brand-identity')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Brand identity*/}
                {/*            </span>*/}
                {/*    </a>*/}
                {/*    <ul className={clsx('subLevel', isOpened("brand-identity") ? 'open' : '')}>*/}
                {/*        <NavItem label={"Accreditation"} path={"/brand-identity/accreditation"}/>*/}
                {/*    </ul>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <a href={"#"} className="itemLabel"*/}
                {/*       onClick={(e) => toggleNavItem(e, 'brand-guidelines')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Brand guidelines*/}
                {/*            </span>*/}
                {/*    </a>*/}
                {/*    <ul className={clsx('subLevel', isOpened("brand-guidelines") ? 'open' : '')}>*/}
                {/*        <NavItem label={"Asset types"} path={"/brand-guidelines/asset-types"}/>*/}
                {/*        <NavItem label={"Asset Colours"} path={"/brand-guidelines/asset-colours"}/>*/}
                {/*        <NavItem label={"Placing Badges"} path={"/brand-guidelines/placing-badges"}/>*/}
                {/*        <NavItem label={"Digital assets"} path={"/brand-guidelines/digital-assets"}/>*/}
                {/*        <NavItem label={"Print assets"} path={"/brand-guidelines/print-assets"}/>*/}
                {/*    </ul>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <a href={"#"} className="itemLabel"*/}
                {/*       onClick={(e) => toggleNavItem(e, 'how-to-create')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                How to create your brand assets*/}
                {/*            </span>*/}
                {/*    </a>*/}
                {/*    <ul className={clsx('subLevel', isOpened("how-to-create") ? 'open' : '')}>*/}
                {/*        <NavItem label={"Accreditation badges"} path={"/how-to-create/accreditation-badges"}/>*/}
                {/*        <NavItem label={"Award badges"} path={"/how-to-create/award-badges"}/>*/}
                {/*    </ul>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*</li>*/}
                <li>
                    <a href={"#"} className="itemLabel"
                       onClick={(e) => toggleNavItem(e, 'asset-downloads')}>
                              <span className="itemLabelInner">
                                Asset downloads
                            </span>
                    </a>
                    <ul className={clsx('subLevel', isOpened("asset-downloads") ? 'open' : '')}>
                        <NavItem label={"Accreditation badge"} path={"/asset-downloads/accreditation-badge"}/>
                        <NavItem label={"Partner awards"} path={"/asset-downloads/partner-awards"}/>
                        <NavItem label={"Logos"} path={"/asset-downloads/logos"}/>
                    </ul>
                    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>
                    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>
                </li>
                 {/*Finalists / Winners page start */}
                {(stageData && (stageData.finalistsRevealed === true || stageData.winnersRevealed === true)) && <li>
                    <a href={"#"} className="itemLabel"
                       onClick={(e) => toggleNavItem(e, 'enterprise-awards')}>
                              <span className="itemLabelInner">
                                Partner awards
                            </span>
                    </a>
                    <ul className={clsx('subLevel', isOpened("enterprise-awards") ? 'open' : '')}>
                        {(stageData && stageData.winnersRevealed != null && stageData.winnersRevealed === true) && <NavItem label={"Winners"} path={"/enterprise-awards/winners"}/>}
                        {(stageData && stageData.finalistsRevealed != null && stageData.finalistsRevealed === true) && <NavItem label={"Finalists"} path={"/enterprise-awards/finalists"}/>}
                    </ul>
                    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>
                    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>
                </li>}
                 {/*Finalists / Winners page end */}
                {/*<>*/}
                {/*    <li>*/}
                {/*        <a href={"#"} className="itemLabel" onClick={(e) => toggleNavItem(e, 'enterprise-awards')}>*/}
				{/*	  <span className="itemLabelInner">*/}
				{/*		Partner awards*/}
				{/*	</span>*/}
                {/*        </a>*/}
                {/*        <ul className={clsx('subLevel', isOpened("enterprise-awards") ? 'open' : '')}>*/}
                {/*            /!*<NavItem label={"Finalists"} path={"/enterprise-awards/finalists"}/>*!/*/}
                {/*            /!*<NavItem label={(stageData && stageData.winnersRevealed != null && stageData.winnersRevealed === true) ? "Winners" : "Finalists"} path={"/enterprise-awards/finalists"}/>*!/*/}
                {/*            <NavItem label={"Awards overview"} path={"/enterprise-awards/overview"}/>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'domain-specialisation-awards')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Domain*/}
                {/*            </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("domain-specialisation-awards") ? 'open' : '')}>*/}
                {/*                    <NavItem label={"Mobility"} path={"/enterprise-awards/domain/mobility"}/>*/}
                {/*                    <NavItem label={"Data and Connectivity"} path={"/enterprise-awards/domain/data-and-connectivity"}/>*/}
                {/*                    /!*<NavItem label={"Data and IP"} path={"/enterprise-awards/domain/data-and-ip"}/>*!/*/}
                {/*                    /!*<NavItem label={"UC"} path={"/enterprise-awards/domain/uc"}/>*!/*/}
                {/*                    /!*<NavItem label={"Cloud"} path={"/enterprise-awards/domain/cloud"}/>*!/*/}
                {/*                    /!*<NavItem label={"IoT"} path={"/enterprise-awards/domain/iot"}/>*!/*/}
                {/*                    <NavItem label={"Services (NAS)"} path={"/enterprise-awards/domain/services"}/>*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'alliance-awards')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Alliance*/}
                {/*            </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("alliance-awards") ? 'open' : '')}>*/}
                {/*                    /!*<NavItem label={"Microsoft Infrastructure"} path={"/enterprise-awards/alliance/ms-infrastructure"}/>*!/*/}
                {/*                    /!*<NavItem label={"Microsoft Workplace"} path={"/enterprise-awards/alliance/ms-modern-wp"}/>*!/*/}
                {/*                    <NavItem label={"Microsoft"} path={"/enterprise-awards/alliance/microsoft"}/>*/}
                {/*                    <NavItem label={"Cisco"} path={"/enterprise-awards/alliance/cisco"}/>*/}
                {/*                    /!*<NavItem label={"Cisco Infrastructure"} path={"/enterprise-awards/alliance/cisco-infrastructure"}/>*!/*/}
                {/*                    /!*<NavItem label={"Cisco Hybrid Working"} path={"/enterprise-awards/alliance/cisco-collaboration"}/>*!/*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'excellence-and-values')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Excellence & Values*/}
                {/*            </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("excellence-and-values") ? 'open' : '')}>*/}
                {/*                    <NavItem label={"Customer excellence"}*/}
                {/*                             path={"/enterprise-awards/excellence-and-values/customer"}/>*/}
                {/*                    <NavItem label={"Channel champion"}*/}
                {/*                             path={"/enterprise-awards/excellence-and-values/channel"}/>*/}
                {/*                    /!*<NavItem label={"Innovation"} path={"/enterprise-awards/excellence-and-values/innovation"}/>*!/*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'state-awards')}>*/}
                {/*                    <span className="itemLabelInner">*/}
                {/*                        State awards*/}
                {/*                    </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("state-awards") ? 'open' : '')}>*/}
                {/*                    /!*<NavItem label={"NSW and ACT"} path={"/enterprise-awards/state-awards/nsw-and-act"}/>*!/*/}
                {/*                    <NavItem label={"NSW"} path={"/enterprise-awards/state-awards/nsw"}/>*/}
                {/*                    <NavItem label={"VIC and TAS"} path={"/enterprise-awards/state-awards/vic-and-tas"}/>*/}
                {/*                    /!*<NavItem label={"VIC"} path={"/enterprise-awards/state-awards/vic"}/>*!/*/}
                {/*                    <NavItem label={"QLD"} path={"/enterprise-awards/state-awards/qld"}/>*/}
                {/*                    <NavItem label={"WA"} path={"/enterprise-awards/state-awards/wa"}/>*/}
                {/*                    <NavItem label={"SA"} path={"/enterprise-awards/state-awards/sa"}/>*/}
                {/*                    /!*<NavItem label={"SA and NT"} path={"/enterprise-awards/state-awards/sa-and-nt"}/>*!/*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'enterprise')}>*/}
                {/*                    <span className="itemLabelInner">*/}
                {/*                        National*/}
                {/*                    </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("enterprise") ? 'open' : '')}>*/}
                {/*                    /!*<NavItem label={"Distributor"} path={"/enterprise-awards/enterprise/distributor"}/>*!/*/}
                {/*                    <NavItem label={"Distribution partner"} path={"/enterprise-awards/enterprise/distribution"}/>*/}
                {/*                    /!*<NavItem label={"Purple partner"} path={"/enterprise-awards/enterprise/purple-partner"}/>*!/*/}
                {/*                    /!*<NavItem label={"Australia Deal"} path={"/enterprise-awards/enterprise/australia-deal"}/>*!/*/}
                {/*                    /!*<NavItem label={"Enterprise partner"} path={"/enterprise-awards/enterprise/enterprise-partner"}/>*!/*/}
                {/*                    <NavItem label={"Enterprise partner"} path={"/enterprise-awards/enterprise/enterprise-partner"}/>*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a href={"#"} className="itemLabel"*/}
                {/*                   onClick={(e) => toggleNavItem(e, 'telstra-nominated')}>*/}
                {/*                    <span className="itemLabelInner">*/}
                {/*                        Telstra nominated*/}
                {/*                    </span>*/}
                {/*                </a>*/}
                {/*                <ul className={clsx('subLevel', isOpened("telstra-nominated") ? 'open' : '')}>*/}
                {/*                    /!*<NavItem label={"Emerging partner"} path={"/enterprise-awards/telstra-nominated/emerging-partner"}/>*!/*/}
                {/*                    <NavItem label={"Service recognition"} path={"/enterprise-awards/telstra-nominated/service-recognition"}/>*/}
                {/*                    <NavItem label={"Hall of fame"} path={"/enterprise-awards/telstra-nominated/hall-of-fame"}/>*/}
                {/*                </ul>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*                <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*            </li>*/}
                {/*            <NavItem label={"Guide to judging criteria"} path={"/enterprise-awards/judging-criteria"}/>*/}
                {/*        </ul>*/}
                {/*        <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*        <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*    </li>*/}
                {/*    {hasGroup('nominations') && <li>*/}
                {/*        <a href={"#"} className="itemLabel"*/}
                {/*           onClick={(e) => toggleNavItem(e, 'nomination')}>*/}
                {/*              <span className="itemLabelInner">*/}
                {/*                Award nominations*/}
                {/*            </span>*/}
                {/*        </a>*/}
                {/*        <ul className={clsx('subLevel', isOpened("nomination") ? 'open' : '')}>*/}
                {/*            /!*<NavItem label={"How to nominate"} path={"/how-to-nominate"}/>*!/*/}
                {/*            /!*{hasGroup('external-nominator') ?*!/*/}
                {/*            /!*    <NavItem label={"Nominate"} path={"/nomination-enterprise-awards"}*!/*/}
                {/*            /!*             isActive={(location.pathname.startsWith("/nomination") && location.pathname !== "/nomination-invite-reviewers" && location.pathname !== "/how-to-nominate")}/> :*!/*/}
                {/*            /!*    <NavItem label={"Nominate"} path={"/nominate"}*!/*/}
                {/*            /!*             isActive={(location.pathname.startsWith("/nomination") && location.pathname !== "/nomination-invite-reviewers" && location.pathname !== "/nominate")}/>}*!/*/}
                {/*            /!* Nominations closed page *!/*/}
                {/*            /!*<NavItem label={"Nominate"} path={"/nominate"}*!/*/}
                {/*            /!*         isActive={(location.pathname.startsWith("/nomination") && location.pathname !== "/nomination-invite-reviewers" && location.pathname !== "/nominate")}/>*!/*/}
                {/*            /!* Close nominations *!/*/}
                {/*            <NavItem label={"Nominate"} path={"/nomination-enterprise-awards"}*/}
                {/*                     isActive={(location.pathname.startsWith("/nomination") && location.pathname !== "/nomination-invite-reviewers" && location.pathname !== "/how-to-nominate")}/>*/}
                {/*            <NavItem label={"Invite reviewers"} path={"/nomination-invite-reviewers"}/>*/}
                {/*        </ul>*/}
                {/*        <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>*/}
                {/*        <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>*/}
                {/*    </li>}*/}
                {/*</>*/}
                <NavItem label={"Brand use terms"} key={"nav-brand-use-terms"} path={"/brand-use-terms"}/>
                <NavItem label={"Submit a request"} key={"nav-submit-a-request"} path={"/submit-a-request"}/>
                {hasGroup('channel-partner-manager') &&
                <li>
                    <a href={"#"} className="itemLabel" onClick={(e) => toggleNavItem(e, 'telstra-staff')}>
                  <span className="itemLabelInner">
                    Telstra staff
                </span>
                    </a>

                    <ul className={clsx('subLevel', isOpened("telstra-staff") ? 'open' : '')}>
                        <NavItem label={"Nomination submission"} path={"/telstra-staff/nomination-submissions-overview"}
                                 isActive={(location.pathname.includes("telstra-staff/nomination-submissions-overview") ||
                                            location.pathname.includes("telstra-staff/nomination-submissions-question"))}/>
                        <NavItem label={"Nominations commenced"} path={"/telstra-staff/nomination-report-commenced"}/>
                        <NavItem label={"Nominations submitted"} path={"/telstra-staff/nomination-report-submitted"}/>
                        <NavItem label={"Entries by category"} path={"/telstra-staff/nomination-report-received"}/>
                        <NavItem label={"Reports"} path={"/telstra-staff/reports"}/>
                    </ul>
                    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>
                    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>
                </li>}
                {hasGroup('channel-partner-admin') && <li>
                    <a href={"#"} className="itemLabel" onClick={(e) => toggleNavItem(e, 'admin')}>
                  <span className="itemLabelInner">
                    Admin
                </span>
                    </a>

                    <ul className={clsx('subLevel', isOpened("admin") ? 'open' : '')}>
                        <NavItem label={"Search Partner"} path={"/admin/search-partner"}/>
                        <NavItem label={"Reports"} path={"/admin/reports"}/>
                    </ul>
                    <span className="td-icon td-icon-xs icon-ui-down-arrow-thick"/>
                    <span className="td-icon td-icon-xs icon-ui-up-arrow-thick"/>
                </li>}
            </ul>
        </div>
    );
}

export default React.memo(Navigation);
